import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  messages: [],
};

export const AppQueries = createSlice({
  name: "appQueries",
  initialState,
  reducers: {
    add_queries: (state, action) => {
      state.messages = action.payload;
    },
    update_queries: (state, action) => {
      state.messages.push(action.payload);
    },
    add_approved: (state, action) => {
      state.approvedCost += parseFloat(action.payload.cost);
    },
  },
});

export const { update_queries, add_queries } = AppQueries.actions;

export const selectQueries = (state) => state.AppQueries.claims;

export default AppQueries.reducer;
