import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import config from "../assets/utils/config";
import { useAuth } from "../assets/utils/useAuth";

import Navbar from "./nav";

const Layout = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user]); //eslint-disable-line react-hooks/exhaustive-deps

  window.onunload = () => {
    localStorage.setItem(config.key.q_count, 0);
    localStorage.setItem(config.key.q_reading, 0);
  };

  return (
    <>
      <Navbar />

      <div className="container justify-content-betwween">
        <Outlet />
      </div>

      <div style={{ textAlign: "center", position: "relative", bottom: 0 }}>
        &copy; {new Date().getFullYear()} Aman Medicare
      </div>
    </>
  );
};

export default Layout;
