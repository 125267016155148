import logoText from "../assets/images/LogoText.png";

const LogoHook = (props) => {
  let style = {
    width: "120px",
    height: "120px",
  };

  return (
    <>
      <div>
        <img src={logoText} style={style} alt="Aman Medicare" />
      </div>
    </>
  );
};

export default LogoHook;
