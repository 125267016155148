import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Select,
  Button,
  Input,
  InputNumber,
  Spin,
  Segmented,
  Empty,
  Row,
  Col,
  Badge,
  Avatar,
  notification,
  Typography,
} from "antd";
import {
  PlusOutlined,
  MinusSquareOutlined,
  SendOutlined,
  LoadingOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import PageTitleHook from "../pagetitle";
import { fetchPaginatedData } from "../../assets/utils/lib";
import debounce from "lodash/debounce";

import {
  add_item,
  reset_items,
  add_diagnosis,
  add_care_category,
  remove_diagnosis,
  remove_care_category,
  clean_items,
  selectItems,
  selectData,
  selectDiagnosis,
  selectCareCategory,
} from "../../stores/preauth";

import "./preauth.css";
import { header, uri, uri_img } from "../../assets/utils/http-request";
import useLocalStorage from "../../assets/utils/useLocal";
import config from "../../assets/utils/config";
import { useAuth } from "../../assets/utils/useAuth";
import { selectclient } from "../../stores/verify";
import Helpers from "../../assets/utils/Helpers";
import CurrencyFormat from "react-currency-format";

const { Option } = Select;

const PreAuthRequestHook = () => {

  const location = useLocation()
  const {state} = location;

  const navigator = useNavigate();
  const authRequestData = useSelector(selectItems);
  const authRequestDataToSubmit = useSelector(selectData);
  const authRequestDiagnosis = useSelector(selectDiagnosis);
  const authRequestCareCategory = useSelector(selectCareCategory);
  const dispatcher = useDispatch();
  const [items, setItems] = useState({});
  const [itemsRaw, setItemsRaw] = useState([]);
  const [itemsRawCopy, setItemsRawCopy] = useState([]); //eslint-disable-line
  const [data, setData] = useState({});
  const [categories, setCategories] = useState([]);
  const [costData, setCostData] = useState({});
  const [diagenosis, setDiagnosis] = useState([]);
  const [careCategories, setCareCategories] = useState([]); //eslint-disable-line
  const [, setCareTypes] = useState([]);
  const [value, setValue] = useState([]);
  const [, setValueCare] = useState([]);
  const [itemsList, setItmesList] = useState({ 1: [], 2: [], 3: [], 4: [] });
  const [, setFreePrice] = useState(false);
  const params = new URLSearchParams(window.location.search);
  const [user] = useLocalStorage(config.key.user);
  const key = "pre";
  const [loadingDiagnosis, setLoadingDiagnosis] = useState(false);
  const [, setLoadingCareCategory] = useState(false);
  const [fetchingItem, setFetchingItem] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [sendingPA, setSendingPA] = useState(false);

  useEffect(() => {
    if (!params.get("checkin") && !state?.checkinType ){ 
      notification.error({message: 'Invalid Chekin Parameters'});
      navigator("/checkins");
    }

    fetch(`${uri}?constableCategoriesList`, {
      headers: {
        ...header,
        Authorization: `Bearer ${user.jwt}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        const dat = data.data.map((c) => {
          const this_dat = {};
          Object.entries(c).forEach((col) => {
            this_dat[col[0]] = col[1] || "";
          });
          return this_dat;
        });
        setCategories(dat);
      })
      .catch((e) =>
        notification.error({
          message: e?.msg || e?.message,
        }),
      );
    fetchCareTypesList();
    addCareType(state?.checkinType)
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const addData = () => {
    const dataKeys = Object.keys(data);

    if (!dataKeys.includes("category") || !dataKeys.includes("item")) return;

    switch (data.category) {
      case 1:
        if (
          !(dataKeys.includes("dosage") && data["dosage"]) ||
          !(dataKeys.includes("frequency") && data["frequency"]) ||
          !(dataKeys.includes("duration") && data["duration"])
        )
          return;
        break;
      default:
        if (
          !(dataKeys.includes("quantity") && data["quantity"]) ||
          !(dataKeys.includes("cost") && data["quantity"])
        )
          return;
    }

    if (Number(data["category"]) === 1 && dataKeys.length > 4) {
      const dt = {
        category: data.category,
        item: data.item,
        quantity: data.quantity,
        cost: data.cost,
        duration: data.duration,
        frequency: data.frequency,
        dosage: data.dosage,
      };
      dispatcher(add_item({ data: dt, items: items }));
    } else {
      const dt = {
        category: data.category,
        item: data.item,
        quantity: data.quantity,
        cost: data.cost,
      };
      dispatcher(add_item({ data: dt, items: items }));
    }

    setData({ category: data.category });
  };

  const addCategory = async (e) => {
    setItemsRaw([]);
    setItems((d) => ({ ...d, category: categories.find((d) => d.id === e) }));
    setData((d) => ({ ...d, category: e, item: "" }));

    const cat = categories.find((d) => d.id === e);
    setSelectedCategory(cat);

    if (itemsList[cat.id].length) {
      setItemsRaw(itemsList[cat.id]);
      setItemsRawCopy(
        itemsList[cat.id].map((item) => ({
          label: (
            <>
              <Typography.Paragraph style={{ margin: 0, padding: 0 }}>
                <strong>Name: </strong>
                {item?.item_name}
              </Typography.Paragraph>
              <Typography.Paragraph style={{ margin: 0, padding: 0 }}>
                <strong>Code: </strong>
                {item?.item_code}
              </Typography.Paragraph>
              {item.item_brand && (
                <Typography.Paragraph style={{ margin: 0, padding: 0 }}>
                  <strong>Brand: </strong>
                  {item?.item_brand}
                </Typography.Paragraph>
              )}
              {item?.item_presentation && (
                <Typography.Paragraph style={{ margin: 0, padding: 0 }}>
                  <strong>Presentation: </strong>
                  {item?.item_presentation}
                </Typography.Paragraph>
              )}
              {item?.item_description && (
                <Typography.Paragraph style={{ margin: 0, padding: 0 }}>
                  <strong>Description: </strong>
                  {item?.item_description}
                </Typography.Paragraph>
              )}
            </>
          ),
          value: item.id,
        })),
      );
    } else {
      setItemsRaw([]);
      setItemsRawCopy([]);
    }
  };

  const addItem = (e) => {
    setItems((d) => ({ ...d, item: itemsRaw.find((d) => d.id === e.value) }));
    setData((d) => ({ ...d, item: e.value }));
    setCostData(0);
    setFreePrice(true);
    setData((d) => ({ ...d, cost: 0 }));

    fetchPrice({ item_id: e.value, facility_id: user?.facility?.facility_id }).then(
      (d) => {
        if (d.length && d[0]["cost"]) {
          setCostData(d[0]);
        } else {
          setFreePrice(true);
        }
      },
    );
  };

  const addQuantity = (e) => {
    if (e < 0) return;
    if (Object.entries(costData).length) {
      setData((d) => ({
        ...d,
        quantity: e,
        cost: Number(costData.cost) * Number(e),
      }));
      setItems((d) => ({
        ...d,
        quantity: e,
        cost: Number(costData.cost) * Number(e),
      }));
    } else {
      setData((d) => ({ ...d, quantity: e }));
      setItems((d) => ({ ...d, quantity: e }));
    }
  };

  const addProvisionalDiagnosis = (e) => {
    const nn = e.map((i) => {
      return diagenosis.find((j) => j.id === i.value).code;
    });
    dispatcher(add_diagnosis({ ...nn }));
    setValue(e);
  };

  const addCareCategory = (e) => { //eslint-disable-line
    dispatcher(
      add_care_category({ ...authRequestCareCategory, careCatValue: e }),
    );
    setValueCare(e);
  };

  const addCareType = async (e) => {
    if(!e){
      notification.error({message: 'Error Fetching Care Category: Unknow Checkin Type'});
      return;
    }
    setCareCategories([]);
    dispatcher(add_care_category({ careTypeValue: e }));
    const payload = { checkinId: params.get("checkin"), careType: e };
    const careCat = await fetchCareCategoriesList(payload);
    const wrangled = careCateogoryListHandler(careCat);
    setCareCategories(wrangled);
  };

  const send_preauth = async () => {
    if (!state?.checkinType) {
      notification.warning({ message: "Kindly Select a Care Type!" });
      return;
    }

    // if (!authRequestCareCategory?.careCatValue) {
    //   notification.warning({ message: "Kindly Select a Care Category!" });
    //   return;
    // }

    // return;

    setSendingPA(true);
    notification.info({
      icon: <LoadingOutlined spin />,
      message: "Sending PA Data",
      key,
      duration: 0,
    });

    const PAData = {
      items: authRequestDataToSubmit,
      diagnosis: authRequestDiagnosis,
      checkinId: params.get("checkin"),
      careCategory:  authRequestCareCategory.careCatValue,
      careType: state?.checkinType,
    };

    const response = await fetch(`${uri}?paLog`, {
      method: "POST",
      body: JSON.stringify(PAData),
      headers: {
        ...header,
        Authorization: `Bearer ${user.jwt}`,
      },
    });

    const body = await response?.json();

    if (body.error) {
      notification.error({ message: body.message, key, duration: 2 });
      setSendingPA(false);
    } else {
      notification.success({ message: body.message, key });
      setTimeout(() => notification.close(key), 3000);
      dispatcher(clean_items({}));
      dispatcher(remove_diagnosis({}));
      dispatcher(remove_care_category());
      setValue([]);
      navigator("/checkins");
    }
  };

  async function fetchDiagnosisList(username) {
    setLoadingDiagnosis(true);
    return fetch(`${uri}?diagnosisList`, {
      method: "POST",
      body: JSON.stringify({ search: username ?? "" }),
      headers: {
        ...header,
        Authorization: `Bearer ${user.jwt}`,
      },
    })
      .then((response) => response.json())
      .then((body) => {
        setDiagnosis((d) => [...body.data, ...d]);
        return careDiagnosisListHandler(body.data);
      })
      .catch((e) => {
        notification.error({
          message: e?.msg || e?.message,
        });
      })
      .finally(setLoadingDiagnosis(false));
  }

  const careDiagnosisListHandler = (items) => {
    return Array.isArray(items)
      ? items.map((item) => {
          return {
            label: (
              <>
                <Typography.Paragraph style={{ margin: 0, padding: 0 }}>
                  <strong>Name: </strong>
                  {item?.name}
                </Typography.Paragraph>
                <Typography.Paragraph style={{ margin: 0, padding: 0 }}>
                  <strong>Code: </strong>
                  {item?.code}
                </Typography.Paragraph>
                {item?.description && (
                  <Typography.Paragraph style={{ margin: 0, padding: 0 }}>
                    <strong>Description: </strong>
                    {item?.description}
                  </Typography.Paragraph>
                )}
              </>
            ),
            value: item?.id,
          };
        })
      : items;
  };

  const careItemListHandler = (items) => {
    return Array.isArray(items)
      ? items.map((item) => {
          return {
            label: (
              <>
                <Typography.Paragraph style={{ margin: 0, padding: 0 }}>
                  <strong>Name: </strong>
                  {item?.item_name}
                </Typography.Paragraph>
                <Typography.Paragraph style={{ margin: 0, padding: 0 }}>
                  <strong>Code: </strong>
                  {item?.item_code}
                </Typography.Paragraph>
                {item.item_brand && (
                  <Typography.Paragraph style={{ margin: 0, padding: 0 }}>
                    <strong>Brand: </strong>
                    {item?.item_brand}
                  </Typography.Paragraph>
                )}
                {item?.item_presentation && (
                  <Typography.Paragraph style={{ margin: 0, padding: 0 }}>
                    <strong>Presentation: </strong>
                    {item?.item_presentation}
                  </Typography.Paragraph>
                )}
                {item?.item_description && (
                  <Typography.Paragraph style={{ margin: 0, padding: 0 }}>
                    <strong>Description: </strong>
                    {item?.item_description}
                  </Typography.Paragraph>
                )}
              </>
            ),
            value: item?.id,
          };
        })
      : items;
  };

  async function fetchCareCategoriesList(payload = {}) {
    setLoadingCareCategory(true);
    const $allData = [];
    try {
      const generator = fetchPaginatedData(
        "post",
        "careCategoriesList",
        payload,
      );

      for await (const data of generator) {
        $allData.push(...data);
      }
    } catch (error) {
      console.error("Error fetching care categories:", error);
    } finally {
      setLoadingCareCategory(false);
      return $allData;
    }
  }

  async function fetchCareTypesList(payload = {}) {
    setLoadingCareCategory(true);
    const $allData = [];
    try {
      const generator = fetchPaginatedData("post", "careTypesList", payload);

      for await (const data of generator) {
        $allData.push(...data);
      }
      setCareTypes(
        $allData.map((item) => ({
          label: item?.checkin_type_name,
          value: item?.checkin_type_id,
        })),
      );
    } catch (error) {
      console.error("Error fetching care categories:", error);
    } finally {
      setLoadingCareCategory(false);
      return $allData;
    }
  }

  const careCateogoryListHandler = (items) => {
    if(!items?.length) {
      notification.error({message: 'Empty Care Category, The Patient is not eligible for this care type'})
      return;
    }
    setCareCategories(items);
    return Array.isArray(items)
      ? items.map((item) => {
          return {
            label: (
              <>
                <Typography.Paragraph style={{ margin: 0, padding: 0 }}>
                  <strong>Name: </strong>
                  {item?.plans_items_name}
                </Typography.Paragraph>
                <Typography.Paragraph style={{ margin: 0, padding: 0 }}>
                  <strong>Description: </strong>
                  {item?.plans_items_description}
                </Typography.Paragraph>
              </>
            ),
            value: item?.plans_items_id,
          };
        })
      : items;
  };

  // async function fetchItemsList(search) {
  //   if (!search) return;

  //   notification.info({
  //     message: "Loading Items List",
  //     key: "itmems",
  //   });

  //   setFetchingItem(true);

  //   return fetch(`${uri}?itemsList`, {
  //     method: "POST",
  //     body: JSON.stringify({ ...selectedCategory, search }),
  //     headers: {
  //       ...header,
  //       Authorization: `Bearer ${user.jwt}`,
  //     },
  //   })
  //     .then((response) => response.json())
  //     .then((body) => {
  //       notification.info({
  //         message: "Items List Loaded",
  //         key: "itmems",
  //       });

  //       setItemsRaw(body.data);
        // const rawData = body.data.map((item) => ({
        //   label: (
        //     <>
        //       <Typography.Paragraph style={{ margin: 0, padding: 0 }}>
        //         <strong>Name: </strong>
        //         {item?.item_name}
        //       </Typography.Paragraph>
        //       <Typography.Paragraph style={{ margin: 0, padding: 0 }}>
        //         <strong>Code: </strong>
        //         {item?.item_code}
        //       </Typography.Paragraph>
        //       {item.item_brand && (
        //         <Typography.Paragraph style={{ margin: 0, padding: 0 }}>
        //           <strong>Brand: </strong>
        //           {item?.item_brand}
        //         </Typography.Paragraph>
        //       )}
        //       {item?.item_presentation && (
        //         <Typography.Paragraph style={{ margin: 0, padding: 0 }}>
        //           <strong>Presentation: </strong>
        //           {item?.item_presentation}
        //         </Typography.Paragraph>
        //       )}
        //       {item?.item_description && (
        //         <Typography.Paragraph style={{ margin: 0, padding: 0 }}>
        //           <strong>Description: </strong>
        //           {item?.item_description}
        //         </Typography.Paragraph>
        //       )}
        //     </>
        //   ),
        //   value: item.id,
        // }))

  //       setItemsRawCopy(rawData)
        
  //       setItmesList((j) => ({
  //         ...j,
  //         [selectedCategory.id]: [...j[selectedCategory.id], ...body.data],
  //       }));

  //       return rawData;
  //     })
  //     .catch((e) =>{
  //       notification.error({
  //         message: e?.msg ?? e?.message,
  //       })
  //       return []
  //     })
  //     .finally(setFetchingItem(false));
  // }

  async function fetchItemsList(search) {
    if (!search) return;
  
    notification.info({
      message: "Loading Items List",
      key: "itmems",
    });
  
    setFetchingItem(true);
    try {
      const response = await fetch(`${uri}?itemsList`, {
        method: "POST",
        body: JSON.stringify({ ...selectedCategory, search }),
        headers: {
          ...header,
          Authorization: `Bearer ${user.jwt}`,
        },
      });
  
      const body = await response.json();
  
      notification.info({
        message: "Items List Loaded",
        key: "itmems",
      });
  
      setItemsRaw(body.data);
  
      const rawData = careItemListHandler(body.data)
  
      setItemsRawCopy(rawData);
  
      setItmesList((j) => ({ ...j, [selectedCategory.id]: [...j[selectedCategory.id], ...body.data], }));

      setFetchingItem(false);
  
      return rawData;
    } catch (e) {
      console.log(e)
      notification.error({
        message: e?.msg ?? e?.message,
      });

      setFetchingItem(false);
  
      return [];
    }
  }
  

  async function fetchPrice(username) {
    notification.info({
      message: "Loading Item Price",
      key: "price",
    });
    return fetch(`${uri}?itemPrice`, {
      method: "POST",
      body: JSON.stringify(username),
      headers: {
        ...header,
        Authorization: `Bearer ${user.jwt}`,
      },
    })
      .then((response) => response.json())
      .then((body) => {
        notification.info({
          message: "Item Price Loaded",
          key: "price",
        });
        return body.data;
      }).catch(e => {
        console.log('Message: ', e?.message || e?.msg)
      });
  }

  const clearPAData = (e) => {
    dispatcher(remove_care_category());
    dispatcher(remove_diagnosis());
    dispatcher(clean_items());
    dispatcher(clean_items());
    navigator("./checkins");
  };

  return (
    <>
      <PageTitleHook
        title={`Compose Pre-Authorization Request`}
        style={{ fontSize: "1.8em", marginTop: ".2em" }}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          marginTop: "30px",
          marginBottom: "10px",
        }}
      >
        <div className="form-group mx-2" style={{ width: "50%" }}>
          {/* <label className="form-label">Care Type</label> */}
          {/* <Select
            showSearch
            value={Number(state?.checkinType)}
            style={{ width: "100%" }}
            placeholder="Search to Select"
            optionFilterProp="children"
            onChange={addCareType}
            options={careTypes}
            disabled
          /> */}
          {/* <br></br> */}
          {/* <label className="form-label">Care Category</label> */}
          {/* <Select
            showSearch
            value={authRequestCareCategory.careCatValue}
            style={{ width: "100%" }}
            placeholder="Search to Select"
            optionFilterProp="children"
            onChange={addCareCategory}
            options={careCategories}
          /> */}
          <br></br>
          <label className="form-label">Diagnosis - Provisional </label>
          <DebounceSelect
            mode="multiple"
            value={value}
            placeholder="Select Provisional Diagnosis"
            fetchOptions={fetchDiagnosisList}
            generatorOption={false}
            onChange={addProvisionalDiagnosis}
            optionHandler={careDiagnosisListHandler}
            style={{ width: "100%" }}
            loadingDiagnosis = {loadingDiagnosis}
            maxTagCount={6}
            aborter
          />
          <br></br>
        </div>
        <div className="form-group mx-2" style={{ width: "50%" }}>
          <div className="form-group mx-2" style={{ width: "100%" }}>
            <label className="form-label me-2">Request Type</label>
            <Select
              value={data?.category}
              style={{ width: "100%" }}
              placeholder="Select"
              optionFilterProp="children"
              filterOption={(input, option) => {
                return (
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                );
              }}
              filterSort={(optionA, optionB) => {
                return optionA.children
                  .toLowerCase()
                  .localeCompare(optionB.children.toLowerCase());
              }}
              onChange={addCategory}
            >
              {categories.map((item) => {
                return <Option value={item.id}>{item.category_name}</Option>;
              })}
            </Select>
          </div>
          <div className="form-group mx-2 gap-2" style={{ width: "100%" }}>
            <label className="form-label me-2">Item Name</label>
            <DebounceSelect2
              disabled = {!Object.entries(selectedCategory).length}
              showSearch
              value={data?.item}
              placeholder="Select Care Request Item"
              fetchOptions={fetchItemsList}
              generatorOption={false}
              onChange={addItem}
              optionHandler={careItemListHandler}
              style={{ width: "100%" }}
              loadingDiagnosis = {fetchingItem}
              aborter
            />
          </div>
          {Object.keys(data).includes("category") && Number(data["category"]) === 1 ? (
            <div
              className="form-group mx-2"
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <div
                className="form-group mx-2 gap-2"
                style={{ width: "33.333333%" }}
              >
                <label className="form-label me-2">Dosage</label>
                <InputNumber
                  style={{ width: "65%" }}
                  placeholder={"12 tablets"}
                  min={0}
                  value={
                    Object.keys(data).length &&
                    Object.keys(data).includes("dosage")
                      ? data["dosage"]
                      : ""
                  }
                  onChange={(e) => {
                    if (e < 0) return;
                    setData((d) => ({ ...d, dosage: e }));
                    setItems((d) => ({ ...d, dosage: e }));
                  }}
                  keyboard={false}
                />
              </div>
              <div className="form-group mx-2" style={{ width: "33.333333%" }}>
                <label className="form-label me-2">Frequency</label>
                <InputNumber
                  style={{ width: "60%" }}
                  placeholder={0}
                  min={0}
                  value={
                    Object.keys(data).length &&
                    Object.keys(data).includes("frequency")
                      ? data["frequency"]
                      : 0
                  }
                  onChange={(e) => {
                    if (e < 0) return;
                    setData((d) => ({ ...d, frequency: e }));
                    setItems((d) => ({ ...d, frequency: e }));
                  }}
                  keyboard={false}
                />
              </div>
              <div className="form-group mx-2" style={{ width: "33.333333%" }}>
                <label className="form-label me-2">Duration</label>
                <InputNumber
                  style={{ width: "65%" }}
                  placeholder={0}
                  min={0}
                  value={
                    Object.keys(data).length &&
                    Object.keys(data).includes("duration")
                      ? data["duration"]
                      : 0
                  }
                  keyboard={false}
                  onChange={(e) => {
                    if (e < 0) {
                      notification.error({
                        message: "Duration Cannot be less than zero!",
                      });
                      return;
                    }
                    setData((d) => ({ ...d, duration: e }));
                    setItems((d) => ({ ...d, duration: e }));
                  }}
                />
              </div>
            </div>
          ) : null}
          <div
            className="form-group mx-2"
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <div className="form-group mx-2" style={{ width: "33.333333%" }}>
              <label className="form-label me-2">Quantity</label>
              <InputNumber
                placeholder={0}
                style={{ width: "65%" }}
                onChange={addQuantity}
                value={
                  Object.keys(data).length &&
                  Object.keys(data).includes("quantity")
                    ? data["quantity"]
                    : 0
                }
                keyboard={false}
              />
            </div>
            <div
              className="form-group mx-2 gap-2"
              style={{ width: "33.333333%" }}
            >
              <label className="form-label me-2">Cost</label>
              <InputNumber
                style={{ width: "80%" }}
                placeholder={0}
                disabled={!Object.keys(costData).length ? false : true}
                onChange={(e) => {
                  if (e < 0) return;
                  setData((d) => ({ ...d, cost: e }));
                  setItems((d) => ({ ...d, cost: e }));
                }}
                value={
                  Object.keys(data).length && Object.keys(data).includes("cost")
                    ? data.cost
                    : 0
                }
                keyboard={false}
              />
            </div>
            <div
              className="form-group mx-2 gap-2"
              style={{
                width: "33.333333%",
                display: "flex",
                placeItems: "center",
              }}
            >
              <Button
                className="btn-dark"
                type="primary"
                onClick={addData}
                style={{ width: "100%" }}
              >
                <PlusOutlined /> Add
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <table className="table align-middle mb-0 bg-white">
          <thead className="bg-light">
            <tr>
              <th>S/N</th>
              <th>Request type</th>
              <th>Item Name</th>
              <th>Dosage</th>
              <th>Frequency</th>
              <th>Duration</th>
              <th>Quantity</th>
              <th>Cost</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {authRequestData.map((item, index) => {
              return (
                <AuthorizationRequestDataListHook data={item} index={index} />
              );
            })}
          </tbody>
        </table>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          margin: "30px",
          justifyContent: "space-between",
          gap: "5em",
        }}
      >
        <Button
          type="primary"
          danger
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          onClick={clearPAData}
          disabled={sendingPA}
          icon={<CloseOutlined />}
        >
          {" "}
          Close
        </Button>
        <Button
          type="primary"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          onClick={send_preauth}
          disabled={!!!authRequestData.length || sendingPA}
          icon={<SendOutlined />}
        >
          {" "}
          Send PAR
        </Button>
      </div>
    </>
  );
};

const AuthorizationRequestDataListHook = (props) => {
  const authRequestData = useSelector(selectData);
  const authRequestItems = useSelector(selectItems);
  const dispatcher = useDispatch();
  const id = props.index;
  // console.log(authRequestData);
  // console.log(authRequestItems);
  // console.log(props);

  const remove = () => {
    let data = authRequestData.filter((d, i) => {
      return i !== id;
    });
    let items = authRequestItems.filter((d, i) => {
      return i !== id;
    });
    dispatcher(reset_items({ data: data, items: items }));
  };

  return (
    <>
      <tr>
        <td>
          <p className="fw-normal mb-1 fw-bold">{id + 1}</p>
        </td>
        <td>
          <p className="fw-normal mb-1 fw-bold">
            {props.data.category.category_name}
          </p>
        </td>
        <td className="fw-bold">
          <p className="fw-normal mb-1 fw-bold">{props.data.item.item_name}</p>
        </td>
        {Number(props.data.category.id) === 1 &&
        Object.keys(props.data).find((i) => i === "dosage") !== undefined ? (
          <td className="fw-bold">
            <p className="fw-normal mb-1 fw-bold">
              <CurrencyFormat
                value={props.data.dosage}
                displayType={"text"}
                thousandSeparator={true}
              />
            </p>
          </td>
        ) : (
          <td></td>
        )}
        {Number(props.data.category.id) === 1 &&
        Object.keys(props.data).find((i) => i === "frequency") !== undefined ? (
          <td className="fw-bold">
            <p className="fw-normal mb-1 fw-bold">
              <CurrencyFormat
                value={props.data.frequency}
                displayType={"text"}
                thousandSeparator={true}
              />
            </p>
          </td>
        ) : (
          <td></td>
        )}
        {Number(props.data.category.id) === 1 &&
        Object.keys(props.data).find((i) => i === "duration") !== undefined ? (
          <td className="fw-bold">
            <p className="fw-normal mb-1 fw-bold">
              <CurrencyFormat
                value={props.data.duration}
                displayType={"text"}
                thousandSeparator={true}
              />
            </p>
          </td>
        ) : (
          <td></td>
        )}
        <td>
          <p className="fw-normal mb-1 fw-bold">
            <CurrencyFormat
              value={props.data.quantity}
              displayType={"text"}
              thousandSeparator={true}
            />
          </p>
        </td>
        <td>
          <p className="fw-normal mb-1 fw-bold">
            <CurrencyFormat
              value={props.data.cost}
              prefix="₦"
              displayType={"text"}
              thousandSeparator={true}
            />
          </p>
        </td>
        <td>
          <Button type="warning" onClick={remove}>
            <MinusSquareOutlined /> Remove
          </Button>
        </td>
      </tr>
    </>
  );
};

function DebounceSelect({
  fetchOptions,
  loadingDiagnosis,
  aborter,
  generatorOption,
  optionHandler,
  debounceTimeout = 800,
  ...props
}) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const [, setLoading] = useState(loadingDiagnosis);
  const fetchRef = useRef(0);

  useEffect(() => {
    let fetchPromise;
    setLoading(true);

    if (generatorOption) {
      fetchPromise = fetchGeneratorOptions();
    } else {
      fetchPromise = fetchOptions();
    }

    fetchPromise
      .then((data) => setOptions(data))
      .finally(() => setLoading(false));
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const debounceFetcher = React.useMemo(() => {
    setLoading(false);
    const loadOptions = async (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      
      setOptions((d) => [
          { label: <Spin size="small" />, value: "_" },
          ...d.filter((i) => i.value !== "_"),
      ]);

      setFetching(true);

      try {
        let fetchPromise;

        if (generatorOption) {
          fetchPromise = fetchGeneratorOptions(value);
        } else {
          fetchPromise = fetchOptions(value);
        }

        const newOptions = await fetchPromise;
        if (fetchId !== fetchRef.current) {
          // For fetch callback order
          return;
        }

        setOptions((d) => [...newOptions]);
      } catch (error) {
        console.error("Error fetching options:", error);
      } finally {
        setFetching(false);
      }
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]); //eslint-disable-line react-hooks/exhaustive-deps

  const fetchGeneratorOptions = async (value) => {
    const generator = fetchOptions(value);
    const options = [];

    for await (const items of generator) {
      if (optionHandler && typeof optionHandler == "function") {
        const opts = optionHandler(items);
        options.push(
          ...opts.filter((o) => !options.find((op) => op.value === o.value)),
        );
      } else {
        options.push(...items);
      }
    }

    return options;
  };

  return (
    <Select
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={
        fetching ? (
          <Spin size="small" tip="Loading ..." />
        ) : (
          <Empty
            description={<span>Empty</span>}
            style={{ padding: 50, width: "100%", height: 300 }}
          />
        )
      }
      {...props}
      options={options}
    />
  );
}

function DebounceSelect2({
  fetchOptions,
  loadingDiagnosis,
  aborter,
  generatorOption,
  optionHandler,
  debounceTimeout = 800,
  ...props
}) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const [, setLoading] = useState(loadingDiagnosis);
  const fetchRef = useRef(0);

  useEffect(() => {
    let fetchPromise;
    setLoading(true);

    if (generatorOption) {
      fetchPromise = fetchGeneratorOptions();
    } else {
      fetchPromise = fetchOptions();
    }

    fetchPromise
      .then((data) => setOptions(data))
      .finally(() => setLoading(false));
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const debounceFetcher = React.useMemo(() => {
    setLoading(true);
    const loadOptions = async (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      options && options.length?
        setOptions((d) => [
          { label: <Spin size="small" />, value: "_" },
          ...d.filter((i) => i.value !== "_"),
        ]) : setOptions((d) => []);

      setFetching(true);

      try {
        let fetchPromise;

        if (generatorOption) {
          fetchPromise = fetchGeneratorOptions(value);
        } else {
          fetchPromise = fetchOptions(value);
        }

        const newOptions = await fetchPromise;
        if (fetchId !== fetchRef.current) {
          // For fetch callback order
          return;
        }

        setOptions((d) => [...newOptions]);
      } catch (error) {
        console.warn("Error fetching options:", error);
      } finally {
        setFetching(false);
      }
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]); //eslint-disable-line react-hooks/exhaustive-deps

  const fetchGeneratorOptions = async (value) => {
    const generator = fetchOptions(value);
    const options = [];

    for await (const items of generator) {
      if (optionHandler && typeof optionHandler === "function") {
        const opts = optionHandler(items);
        options.push(
          ...opts.filter((o) => !options.find((op) => op.value === o.value))
        );
      } else {
        options.push(...items);
      }
    }

    return options;
  };

  return (
    <Select
      labelInValue
      showSearch
      filterOption = {false}
      // filterOption={(input, option) =>
      //   option && option.label && option.label.toLowerCase().includes(input.toLowerCase())
      // }
      onSearch={debounceFetcher}
      notFoundContent={
        fetching ? (
          <Spin size="small" tip="Loading ..." />
        ) : (
          <Empty
            description={<span>Empty</span>}
            style={{ padding: 50, width: "100%", height: 300 }}
          />
        )
      }
      {...props}
      options={options}
    />
  );
}


const PreAuthListHook = () => {
  const user = JSON.parse(localStorage.getItem(config.key.user));
  const [claimList, setClaimsList] = useState([]);
  const [filterdClaimList, setFilterdClaimsList] = useState([]);
  const [activeSegment, setActiveSegment] = useState(Number(user?.role));
  const [filterKeyword, setFilterKeyword] = useState("");
  const [loadingData, setLoadingData] = useState(false);
  const navigate = useNavigate();
  const segment = useRef(4);
  const loading = useRef(false);
  const searchKey = useRef("");
  const interval = useRef();
  const itemStatusesKeys = ["Pending", "Approved", "Queried", "Declined"];

  const itemStatuses = {
    Declined: {
      count: 3,
      color: "#ff0200",
      text: "Declined",
    },
    Queried: {
      count: 0,
      color: "#ff9200",
      text: "Queried",
    },
    Pending: {
      count: 0,
      color: "#121221",
      text: "Pending",
    },
    Approved: {
      count: 5,
      color: "#00aa00",
      text: "Approved",
    },
  };

  useEffect(() => {
    loading.current = true;
    fetchClaimsList(user?.facility?.facility_id)
      .then((res) => {
        const dat = Object.values(res)
          .map((c) => {
            const this_dat = {};
            Object.entries(c).forEach((col) => {
              this_dat[col[0]] = col[1] || "";
            });
            return this_dat;
          })
          .sort((a, b) => new Date(b.checkin_date) - new Date(a.checkin_date));

        setClaimsList(dat);
        setFilterdClaimsList(() => {
          return segment.current === 4
            ? dat
            : dat
                .filter(
                  (val) =>
                    val.aggregatedItemsStatus.cat[
                      itemStatusesKeys[segment.current]
                    ]["count"] > 0,
                )
                .map((item) => {
                  item.aggregatedItemsStatus.code = segment.current;
                  item.aggregatedItemsStatus.text =
                    itemStatuses[itemStatusesKeys[segment.current]]["text"];
                  item.aggregatedItemsStatus.color =
                    itemStatuses[itemStatusesKeys[segment.current]]["color"];
                  return item;
                });
        });
      })
      .catch((e) => {
        console.log("message:", e?.msg ?? e?.message);
        setLoadingData(false);
      })
      .finally(() => {
        interval.current = setInterval(() => {
          if (loading.current) return;
          if (window.location.pathname === "/preauth") {
            loading.current = true;
            fetchClaimsList(user.facility.facility_id)
              .then((res) => {
                const dat = Object.values(res)
                  .map((c) => {
                    const this_dat = {};
                    Object.entries(c).forEach((col) => {
                      this_dat[col[0]] = col[1] || "";
                    });
                    return this_dat;
                  })
                  .sort(
                    (a, b) =>
                      new Date(b.checkin_date) - new Date(a.checkin_date),
                  );
                setClaimsList(dat);
                setFilterdClaimsList(() => {
                  return segment.current === 4
                    ? dat
                    : dat
                        .filter(
                          (val) =>
                            val.aggregatedItemsStatus.cat[
                              itemStatusesKeys[segment.current]
                            ]["count"] > 0,
                        )
                        .map((item) => {
                          item.aggregatedItemsStatus.code = segment.current;
                          item.aggregatedItemsStatus.text =
                            itemStatuses[itemStatusesKeys[segment.current]][
                              "text"
                            ];
                          item.aggregatedItemsStatus.color =
                            itemStatuses[itemStatusesKeys[segment.current]][
                              "color"
                            ];
                          return item;
                        });
                });
              })
              .catch((e) => {
              })
              .finally(
                (loading.current = false),
              );
          }
        }, 5000);
        loading.current = false;
      });
    return () => clearInterval(interval.current);
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    filterClaimsList(filterKeyword);
  }, [filterKeyword]); //eslint-disable-line react-hooks/exhaustive-deps


  async function fetchClaimsList(claimsId) {
    if (loadingData) return;
    return fetch(`${uri}?submittedPAList`, {
      method: "POST",
      body: JSON.stringify({ facility_id: claimsId }),
      headers: {
        ...header,
        Authorization: `Bearer ${user.jwt}`,
      },
    })
      .then((response) => response.json())
      .then((body) => {
        return body.data;
      }).catch(e => {
        console.log('Message: ', e?.message || e?.msg)
      });
  }

  const handleSegmentChange = (value) => {
    segment.current = parseInt(value);
    switch (value) {
      default:
        break;
      case 0:
      case 1:
      case 2:
      case 3:
        setFilterdClaimsList(
          Object.values(claimList)
            .filter(
              (val) =>
                val.aggregatedItemsStatus.cat[itemStatusesKeys[value]][
                  "count"
                ] > 0,
            )
            .map((item) => {
              item.aggregatedItemsStatus.code = value;
              item.aggregatedItemsStatus.text =
                itemStatuses[itemStatusesKeys[value]]["text"];
              item.aggregatedItemsStatus.color =
                itemStatuses[itemStatusesKeys[value]]["color"];
              return item;
            })
            .sort(
              (a, b) => new Date(b.checkin_date) - new Date(a.checkin_date),
            ),
        );
        break;
      case 4:
        setFilterdClaimsList(
          Object.values(claimList).sort(
            (a, b) => new Date(b.checkin_date) - new Date(a.checkin_date),
          ),
        );
        break;
    }

    setActiveSegment(Number(value));
    setFilterKeyword("");
  };

  const filterClaimsList = (e) => {
    const inputValue = e;
    searchKey.current = e;
    if (inputValue) {
      setFilterdClaimsList(
        claimList
          .filter((val) => {
            return segment.current
              ? (val.surname.toLowerCase().search(inputValue) >= 0 ||
                  val.first_name.toLowerCase().search(inputValue) >= 0 ||
                  val.middle_name.toLowerCase().search(inputValue) >= 0 ||
                  val.insurance_no.toLowerCase().search(inputValue) >= 0 ||
                  val.code.toLowerCase().search(inputValue) >= 0 ||
                  val.checkin_date.toLowerCase().search(inputValue) >= 0 ||
                  val.checkinType.toLowerCase().search(inputValue) >= 0 ||
                  val.policy_no.toLowerCase().search(inputValue) >= 0) &&
                  Number(val.aggregatedItemsStatus.code) === segment.current
              : val.surname.toLowerCase().search(inputValue) >= 0 ||
                  val.first_name.toLowerCase().search(inputValue) >= 0 ||
                  val.middle_name.toLowerCase().search(inputValue) >= 0 ||
                  val.insurance_no.toLowerCase().search(inputValue) >= 0 ||
                  val.code.toLowerCase().search(inputValue) >= 0 ||
                  val.checkin_date.toLowerCase().search(inputValue) >= 0 ||
                  val.checkinType.toLowerCase().search(inputValue) >= 0 ||
                  val.policy_no.toLowerCase().search(inputValue) >= 0;
          })
          .sort((a, b) => new Date(b.checkin_date) - new Date(a.checkin_date)),
      );
    } else {
      segment.current
        ? setFilterdClaimsList(
            claimList
              .filter(
                (val) => Number(val.aggregatedItemsStatus.code) === segment.current,
              )
              .sort(
                (a, b) => new Date(b.checkin_date) - new Date(a.checkin_date),
              ),
          )
        : setFilterdClaimsList(
            claimList.sort(
              (a, b) => new Date(b.checkin_date) - new Date(a.checkin_date),
            ),
          );
    }
  };

  const style = {
    padding: "8px 0",
    justifyContent: "center",
    textAlign: "center",
    fontSize: "1.2em",
    fontWeight: "bold",
  };

  return (
    <>
      <Segmented
        block
        options={[
          {
            label: (
              <div style={{ ...style, color: "#0092ff", cursor: "pointer" }}>
                All
              </div>
            ),
            value: 4,
          },
          {
            label: (
              <div style={{ ...style, color: "#121221", cursor: "pointer" }}>
                Pending
              </div>
            ),
            value: 0,
          },
          {
            label: (
              <div style={{ ...style, color: "#00aa00", cursor: "pointer" }}>
                Approved
              </div>
            ),
            value: 1,
          },
          {
            label: (
              <div style={{ ...style, color: "#ff9200", cursor: "pointer" }}>
                Queried
              </div>
            ),
            value: 2,
          },
          {
            label: (
              <div style={{ ...style, color: "#f5222d", cursor: "pointer" }}>
                Declined
              </div>
            ),
            value: 3,
          },
        ]}
        onChange={handleSegmentChange}
        style={{ userSelect: "none" }}
        values={activeSegment}
      />
      <div style={{ marginTop: 10 }}>
        <Row justify="end" align="middle">
          <Col span={2}>
            <span style={{ fontWeight: "bolder", fontSize: "1.2em" }}>
              Search:
            </span>
          </Col>
          <Col span={8}>
            <Input
              placeholder="Type Filter Word"
              value={filterKeyword.toLowerCase()}
              onChange={(e) => setFilterKeyword(e.target.value.toLowerCase())}
            />
          </Col>
        </Row>

        {filterdClaimList.length ? (
          filterdClaimList.map((claim) => (
            <>
              <Row
                style={{ padding: "10px 0", cursor: "pointer" }}
                onClick={() => {
                  navigate(
                    `/preauth-action?checkin=${claim.code}${
                      segment.current !== 4
                        ? "&filter=" + itemStatusesKeys[segment.current]
                        : ""
                    }`,
                  );
                }}
              >
                <Col span={24}>
                  <Badge.Ribbon
                    text={
                      <>
                        <Badge count={claim.queries || null} offset={[10, -8]}>
                          <span style={{ color: "white" }}>
                            {claim.aggregatedItemsStatus.text}
                          </span>
                        </Badge>
                      </>
                    }
                    color={claim.aggregatedItemsStatus.color}
                    placement="end"
                  >
                    <Row
                      justify="space-between"
                      style={{
                        padding: "2px 10px",
                        boxShadow: "2px 2px 5px rgba(0,0,0, .4)",
                      }}
                      align="middle"
                    >
                      <Col span={2}>
                        <Avatar
                          size={64}
                          icon={
                            <img
                              src={`${uri_img}${claim.pic_name}`}
                              alt={claim.first_name}
                            />
                          }
                        />
                      </Col>
                      <Col span={5}>
                        <p>{claim.code}</p>
                      </Col>
                      <Col span={5} alignItems="center">
                        <p>
                          {Array.from(claim.surname)
                            .map((i, v) => i.toLocaleUpperCase())
                            .join("")}
                          , &nbsp;
                          {Array.from(claim.first_name)
                            .map((i, v) => (v === 0 ? i.toLocaleUpperCase() : i))
                            .join("")}{" "}
                          &nbsp;
                          {Array.from(claim.middle_name)
                            .map((i, v) => (v === 0 ? i.toLocaleUpperCase() : i))
                            .join("")}
                        </p>
                      </Col>
                      <Col span={2}>
                        <p>{new Date(claim.checkin_date).toLocaleString()}</p>
                      </Col>
                      <Col span={2}>
                        <p>{claim.pa_items_count}</p>
                      </Col>
                      <Col span={4}>
                        <Row justify="space=between">
                          <Col
                            span={12}
                            style={{
                              color: `${claim.aggregatedItemsStatus.cat.Pending.color}`,
                            }}
                          >
                            {claim.aggregatedItemsStatus.cat.Pending.text}:
                          </Col>
                          <Col>
                            {claim.aggregatedItemsStatus.cat.Pending.count}
                          </Col>
                        </Row>
                        <Row justify="space=between">
                          <Col
                            span={12}
                            style={{
                              color: `${claim.aggregatedItemsStatus.cat.Queried.color}`,
                            }}
                          >
                            {claim.aggregatedItemsStatus.cat.Queried.text}:
                          </Col>
                          <Col>
                            {claim.aggregatedItemsStatus.cat.Queried.count}
                          </Col>
                        </Row>
                        <Row justify="space=between">
                          <Col
                            span={12}
                            style={{
                              color: `${claim.aggregatedItemsStatus.cat.Approved.color}`,
                            }}
                          >
                            {claim.aggregatedItemsStatus.cat.Approved.text}:
                          </Col>
                          <Col>
                            {claim.aggregatedItemsStatus.cat.Approved.count}
                          </Col>
                        </Row>
                        <Row justify="space=between">
                          <Col
                            span={12}
                            style={{
                              color: `${claim.aggregatedItemsStatus.cat.Declined.color}`,
                            }}
                          >
                            {claim.aggregatedItemsStatus.cat.Declined.text}:
                          </Col>
                          <Col>
                            {claim.aggregatedItemsStatus.cat.Declined.count}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Badge.Ribbon>
                </Col>
              </Row>
            </>
          ))
        ) : (
          <>
            <Empty
              description={<span>There are no claims in this category</span>}
              style={{ padding: 50, width: "100%" }}
            >
            </Empty>
          </>
        )}
      </div>
    </>
  );
};

const PreAuthHook = () => {
  const params = new URLSearchParams(window.location.search);
  const dispatcher = useDispatch(selectclient);
  const { set, user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      if (![1, 3].includes(Number(user["role"]))) {
        Helpers.logout(set, dispatcher);
        notification.info({
          message: "Invalid User",
          description: "Please login with the right permission",
        });
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  });

  return (
    <>{params.get("checkin") ? <PreAuthRequestHook /> : <PreAuthListHook />}</>
  );
};

export default PreAuthHook;
