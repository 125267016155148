import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Draggable from "react-draggable";
import {
  Select,
  Button,
  Modal,
  Input,
  Spin,
  Row,
  Col,
  notification,
  Drawer,
  Space,
  Typography,
  Empty,
  Badge,
} from "antd";
import { IoReturnUpBack } from "react-icons/io5";
import CurrencyFormat from "react-currency-format";
import debounce from "lodash/debounce";

import { header, uri, uri_img } from "../../assets/utils/http-request";
import { useAuth } from "../../assets/utils/useAuth";
import Helpers from "../../assets/utils/Helpers";
import { myRequestObj } from "../../assets/utils/lib";
import {  add_claim } from "../../stores/Claims";
import { add_queries } from "../../stores/queries";
import { selectclient } from "../../stores/verify";
import config from "../../assets/utils/config";
import logo from "../../assets/images/logo.png";
import PageTitleHook from "../pagetitle";
import "./claims.css";
import { MessageOutlined } from "@ant-design/icons";

const { TextArea } = Input;

const ClaimsHook = () => {
  const dispatcher = useDispatch();
  const [approvedCostD, setApprovedCostD] = useState(0);
  const [items, setItems] = useState([]);
  const [response, setResponse] = useState({});
  const [, setDiagnosis] = useState([]);
  const [provDiagenosis, setProvDiagenosis] = useState([]);
  const [finalDiagenosis, setFinalDiagenosis] = useState([]);
  const [, setValue] = useState([]);
  const params = new URLSearchParams(window.location.search);
  const navigator = useNavigate();
  const [client, setClient] = useState({});
  const [totalCost, setTotalCost] = useState(0);

  const navigate = useNavigate();
  const { set, user } = useAuth();
  const dispatcherclient = useDispatch(selectclient);

  useEffect(() => {
    if (user) {
      if (![0, 2, 3].includes(Number(user.role))) {
        Helpers.logout(set, dispatcherclient);
        notification.info({
          message: "Invalid User",
          description: "Please login with the right permission",
        });
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  });

  useEffect(() => {
    if (!params.get("encounter")) navigator("/claims");
    fetchPAData(params.get("encounter")).then((res) => {
      setItems(res.items);
      setTotalCost(
        res.items.reduce((i, j) => i + parseFloat(j.approved_cost), 0),
      );
      setApprovedCostD(
        res.items.reduce((i, j) => {
          return Number(j.claim_flag) === 1
            ? i + parseFloat(j.approved_cost)
            : i + 0;
        }, 0),
      );
      setClient(res.misc);
      dispatcher(add_claim(res.items));
      setResponse(res.claim);
      updateList();
    });
  }, []); //eslint-disable-line react-hooks/exhaustive-deps


  async function fetchDiagnosisList(username) {
    return myRequestObj(`diagnosisList`).post({ search: username })
      .then((response) => response?.data)
      .then((body) => {
        setDiagnosis(body?.data);
        return body?.data.map((user) => ({
          label: `${user?.code} ${user?.name}`,
          value: user?.id,
        }));
      });
  }

  async function fetchPAData(claimsId) {
    return myRequestObj(`PAListAdminClaim`).post({ checkinId: claimsId })
      .then((response) => response?.data)
      .then((body) => {
        const provList = body?.data?.diagnosis?.provisional?.map((d) => ({
          label: `${d.code} ${d.name}`,
          value: d.id,
        }));
        const finalList = body?.data?.diagnosis?.final?.map((d) => ({
          label: `${d.code} ${d.name}`,
          value: d.id,
        }));
        setProvDiagenosis(provList);
        setFinalDiagenosis(finalList);
        return body?.data;
      });
  }

  const addDiagnosis = (e) => {
    setValue(e);
  };

  const updateList = () => {
    setInterval(() => {
      if (window.location.pathname === "/claims-action") {
        fetchPAData(params.get("encounter")).then((res) => {
          setItems(res.items);
          setTotalCost(
            res.items.reduce((i, j) => i + parseFloat(j.approved_cost), 0),
          );
          setApprovedCostD(
            res.items.reduce((i, j) => {
              return Number(j.claim_flag) === 1
                ? i + parseFloat(j.approved_cost)
                : i + 0;
            }, 0),
          );
          dispatcher(add_claim(res.items));
          setResponse(res.claim);
        });
      }
    }, 3000);
  };

  return (
    <>
      <PageTitleHook
        title={`Care Claims for ${client.surname}, ${client.first_name}`}
        style={{ fontSize: "1.8em", marginTop: ".2em" }}
      />
      <Row justify="space-between" alignItems="center">
        <Col span={16}>
          <ClientDataHook data={client} style={{ maxWidth: 750 }} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <div
              className="form-group"
              style={{ width: "100%", marginBottom: "5px" }}
            >
              <label className="form-label">Medical Complaints</label>
              <TextArea
                rows={3}
                style={{ width: "100%" }}
                value={client.medical_complaint}
                disabled
              />
            </div>
            <div
              className="form-group"
              style={{ width: "100%", marginTop: "5px", marginBottom: "5px" }}
            >
              <label className="form-label">Diagnosis - Final</label>
              <DebounceSelect
                mode="multiple"
                value={finalDiagenosis}
                placeholder="Select Final Diagnosis"
                fetchOptions={fetchDiagnosisList}
                onChange={addDiagnosis}
                style={{ width: "100%" }}
                disabled
              />
            </div>
          </div>
        </Col>
        <Col span={6}>
          <div
            className="form-group"
            style={{ width: "100%", marginBottom: "5px" }}
          >
            <label className="form-label">Encounter Code</label>
            <Input
              rows={3}
              style={{
                width: "100%",
                backgroundColor: "white",
                color: "black",
                fontSize: "1.4em",
                fontWeight: "bolder",
              }}
              value={params.get("encounter")}
              disabled
            />
          </div>
          <div
            className="form-group"
            style={{ width: "100%", marginBottom: "5px" }}
          >
            <label className="form-label">Encounter Date</label>
            <Input
              rows={3}
              style={{
                width: "100%",
                backgroundColor: "white",
                color: "black",
                fontSize: "1.4em",
                fontWeight: "bolder",
              }}
              value={new Date(client.checkin_date).toString().split("GMT")[0]}
              disabled
            />
          </div>
          <div
            className="form-group"
            style={{ width: "100%", marginBottom: "5px" }}
          >
            <label className="form-label">Total Bill</label>
            <span
              style={{
                display: "block",
                border: "3px solid grey",
                color: "blue",
                fontSize: 32,
                fontWeight: "bolder",
                padding: "5px 20px",
              }}
            >
              <CurrencyFormat
                value={totalCost}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₦"}
              />
            </span>
          </div>
          <div
            className="form-group"
            style={{ width: "100%", marginBottom: "5px" }}
          >
            <label className="form-label">Total Approved</label>
            <span
              style={{
                display: "block",
                border: "3px solid grey",
                color: "green",
                fontSize: 32,
                fontWeight: "bolder",
                padding: "5px 20px",
              }}
            >
              <CurrencyFormat
                value={approvedCostD}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₦"}
              />
            </span>
          </div>
          <div
            className="form-group"
            style={{ width: "100%", marginBottom: "5px" }}
          >
            <label className="form-label">Variance</label>
            <span
              style={{
                display: "block",
                border: "3px solid grey",
                color: "red",
                fontSize: 32,
                fontWeight: "bolder",
                padding: "5px 20px",
              }}
            >
              <CurrencyFormat
                value={totalCost - approvedCostD}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"₦"}
              />
            </span>
          </div>
        </Col>
      </Row>
      <hr />
      <div style={{ maxHeight: "450px", overflowY: "auto" }}>
        <label className="form-label">Diagnosis - Provisional</label>
        <Select
          mode="multiple"
          value={provDiagenosis}
          style={{ width: "100%" }}
          disabled
        ></Select>
      </div>
      <div style={{ marginTop: 10 }}>
        <table className="table align-middle mb-0 bg-white">
          <thead>
            <tr style={{ fontWeight: "bolder" }}>
              <td>S/N</td>
              <td>Authorization Code</td>
              <td>Requested Item</td>
              <td>Quantity</td>
              <td>Cost</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => {
              return Number(item.approved_cost) > 0 ? (
                <AuthorizationRequestDataListHook
                  data={item}
                  index={index}
                  user={user}
                  claim={response}
                />
              ) : null;
            })}
          </tbody>
        </table>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
          margin: "30px",
          justifyContent: "flex-end",
          gap: "2em",
        }}
      >
        <Button
          type="primary"
          onClick={() => navigator("/claims")}
          icon={
            <IoReturnUpBack style={{ fontSize: "30px", marginRight: 10 }} />
          }
        >
          Close
        </Button>
      </div>
    </>
  );
};

const AuthorizationRequestDataListHook = (props) => {
  const [visibleAQuery, setVisibleQuery] = useState(false);
  const [visibleApproval, setVisibleApproval] = useState(false);
  const [, setComment] = useState("");
  var claimData = { ...props.data };

  // Modal control start
  const [disabled, setDisabled] = useState(false);

  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });

  const draggleRef = useRef(null);

  const showApprovalComment = () => {
    setVisibleApproval(true);
  };

  const showQueryComment = () => {
    setVisibleQuery(true);
  };

  const handleCancel = (e) => {
    setVisibleApproval(false);
    setVisibleQuery(false);
  };

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();

    if (!targetRect) {
      return;
    }

    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  return (
    <>
      <tr>
        <td className="fw-bold">
          {props.index + 1}
        </td>
        <td>
          <span>
            <strong>{props.data.auth_code}</strong>
          </span>
        </td>
        <td>
          <span>
            <strong>{`${props.data.category_name.split(" ")[0]} - ${
              props.data.item_name
            }`}</strong>
          </span>
        </td>
        <td>
          <span>
            <strong>{props.data.quantity}</strong>
          </span>
        </td>
        <td>
          <span>
            <strong>{props.data.approved_cost}</strong>
          </span>
        </td>
        {[0, 2].includes(Number(props.user.role)) ? (
          <td>
            <Space style={{ display: "flex", gap: "5px 5px" }}>
              {Number(props.data.claim_flag) === 1 ? (
                <Button type="primary" onClick={showApprovalComment}>
                  Approval Memo
                </Button>
              ) : Number(props.data.claim_flag) === 3 ? (
                <Button
                  type="dashed"
                  danger
                  disabled={Number(props.data.claim_flag) > 2 ? false : true}
                  onClick={showQueryComment}
                >
                  Rejection Memo
                </Button>
              ) : (
                <Button disabled>Pending</Button>
              )}
              <PAQuery data={props.data} claim={props.claim} />
            </Space>
          </td>
        ) : null}

        <Modal
          title={
            <div
              style={{
                width: "100%",
                cursor: "move",
              }}
              onMouseOver={() => {
                if (disabled) {
                  setDisabled(false);
                }
              }}
              onMouseOut={() => {
                setDisabled(true);
              }}
              onFocus={() => {}}
              onBlur={() => {}} // end
            >
              Approval Memo
            </div>
          }
          open={visibleApproval}
          okButtonProps={{ disabled: true }}
          onCancel={handleCancel}
          footer={null}
          modalRender={(modal) => (
            <Draggable
              disabled={disabled}
              bounds={bounds}
              onStart={(event, uiData) => onStart(event, uiData)}
            >
              <div ref={draggleRef}>{modal}</div>
            </Draggable>
          )}
        >
          <div
            className="form-group"
            style={{ width: "100%", marginBottom: "5px" }}
          >
            <label className="form-label">Complaints</label>
            <TextArea
              rows={3}
              style={{ width: "100%" }}
              onChange={(e) => setComment(e.target.value)}
              readOnly
              value={claimData.claim_comment}
            />
          </div>
        </Modal>

        <Modal
          title={
            <div
              style={{
                width: "100%",
                cursor: "move",
              }}
              onMouseOver={() => {
                if (disabled) {
                  setDisabled(false);
                }
              }}
              onMouseOut={() => {
                setDisabled(true);
              }}
              onFocus={() => {}}
              onBlur={() => {}} // end
            >
              Rejection Memo
            </div>
          }
          footer={null}
          open={visibleAQuery}
          onCancel={handleCancel}
          okButtonProps={{ disabled: true }}
          modalRender={(modal) => (
            <Draggable
              disabled={disabled}
              bounds={bounds}
              onStart={(event, uiData) => onStart(event, uiData)}
            >
              <div ref={draggleRef}>{modal}</div>
            </Draggable>
          )}
          okText={"Query"}
        >
          <div
            className="form-group"
            style={{ width: "100%", marginBottom: "5px" }}
          >
            <label className="form-label">Complaints</label>
            <TextArea
              rows={3}
              style={{ width: "100%" }}
              onChange={(e) => setComment(e.target.value)}
              value={claimData.claim_comment}
            />
          </div>
        </Modal>
      </tr>
    </>
  );
};

function DebounceSelect({ fetchOptions, debounceTimeout = 800, ...props }) {
  const [fetching, setFetching] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const fetchRef = React.useRef(0);
  const debounceFetcher = React.useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          return;
        }
        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <Select
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
    />
  );
}

const PAQuery = ({ data, claim }) => {
  const dispatcher = useDispatch();
  const { user } = useAuth();
  const queries = useSelector((state) => state.QueriesStore.messages).filter(
    (i) => i.marker === data.id_ && i.level === 1,
  );
  const [loading, setLoading] = useState(false);
  const [, setsending] = useState(false);
  const [visible, setVisible] = useState(false);
  const [messageText, setMessageText] = useState("");
  const [queried, setQueried] = useState(0);

  const [messageData, ] = useState({
    relation: "claim_items",
    marker: data.id_,
    source: 1,
    level: 1,
    facility_id: user.facility.facility_id,
    facility_type: 1,
  });

  const [, setFirstLoad] = useState(true);
  const [unreadQueriesCount, setUnreadQueriesCount] = useState(0);
  const [reading, setReading] = useState(false);

  let interval = useRef();
  let interval2 = useRef();

  useEffect(() => {
    const payload = { id_: data.id_, source: 0, level: 1 };
    fetch(`${uri}?queriesCount`, {
      method: "post",
      body: JSON.stringify(payload),
      headers: {
        ...header,
        Authorization: `Bearer ${user.jwt}`,
      },
    })
      .then((res) => res.json())
      .then((body) => {
        if (!body.error) {
          setUnreadQueriesCount(body?.data.queris);
          setQueried(Number(body?.data[1].querid));
        }
      }).catch(e => {
        console.log('Message: ', e?.message || e?.msg)
      })
      .finally(() => {
        if (interval2.current) clearInterval(interval2.current);
        interval2.current = setInterval(() => {
          if (!reading && window.location.pathname === "/claims-action") {
            fetch(`${uri}?queriesCount`, {
              method: "post",
              body: JSON.stringify(payload),
              headers: {
                ...header,
                Authorization: `Bearer ${user.jwt}`,
              },
            })
              .then((res) => res.json())
              .then((body) => {
                if (!body.error) {
                  console.log(body);
                  setUnreadQueriesCount(body?.data[0].queris);
                  setQueried(Number(body?.data[1].querid));
                }
              }).catch(e => {
                console.log('Message: ', e?.message || e?.msg)
              });
          }
        }, 2000);
      });
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setLoading(true);

    if (visible) {
      localStorage.setItem(config.key.q_reading, 1);
    }

    fetchQueries(visible).then((res) => {
      setLoading(false);
      updateQueries();
    });

    setReading(true);
  }, [visible]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const queriesToUpdate = queries
      .filter((j) => !Number(j.status) && !Number(j.source))
      .map((i) => {
        return {
          id: i.id,
        };
      });

    if (visible && queriesToUpdate.length) {
      fetch(uri + "?update_queries", {
        method: "POST",
        body: JSON.stringify(queriesToUpdate),
        headers: {
          ...header,
          Authorization: `Bearer ${user.jwt}`,
        },
      })
        .then((res) => res.json())
        .catch((e) => console.log(e.msg ?? e.message))
        .finally(setFirstLoad(false));
    }
  }, [visible, queries]); //eslint-disable-line react-hooks/exhaustive-deps

  const handleOk = () => {
    setsending(true);
    const payload = { content: messageText, ...messageData };
    fetch(`${uri}?query`, {
      method: "post",
      body: JSON.stringify(payload),
      headers: {
        ...header,
        Authorization: `Bearer ${user.jwt}`,
      },
    })
      .then((res) => res.json())
      .then((body) => {
        if (!body.error) {
          dispatcher(add_queries(body.data));
        }
        setsending(false);
        setMessageText("");
      }).catch(e => {
        console.log('Message: ', e?.message || e?.msg)
      });
  };

  const handleCancel = () => {
    localStorage.setItem(config.key.q_reading, 0);
    clearInterval(interval.current);
    setVisible(false);
    setReading(false);
  };

  const contoller = () => {
    setVisible(true);
  };

  const composeMessage = (e) => {
    const mess = e.target.value;
    setMessageText(mess);
  };

  const fetchQueries = async (x) => {
    if (!x) return;
    return myRequestObj(`queries`).post(messageData)
      .then((res) => res?.data)
      .then((body) => {
        if (!body.error && body.data.length) {
          dispatcher(add_queries(body.data));
        }
      });
  };

  const updateQueries = () => {
    interval.current = setInterval(() => fetchQueries(visible), 5000);
  };

  return (
    <>
      {([1, 2, 3].includes(Number(claim.claims_status)) && queried) ||
      ([1, 3].includes(Number(data.claim_flag)) && queried) ||
      Number(data.claim_flag) === 2 ? (
        <Badge count={Number(unreadQueriesCount) || null}>
          <MessageOutlined
            style={{
              fontSize: "2em",
              fontWeight: "bolder",
              color:
                !Number(data.claim_flag) ||
                Number(data.claim_flag) === 2 ||
                queried
                  ? "#ff7875"
                  : "grey",
            }}
            onClick={() =>
              !Number(data.claim_flag) ||
              Number(data.claim_flag) === 2 ||
              queried
                ? contoller()
                : null
            }
            disabled={
              !Number(data.claim_flag) ||
              Number(data.claim_flag) === 2 ||
              queried
                ? false
                : true
            }
            title="Query"
          />
        </Badge>
      ) : null}
      <Drawer
        open={visible}
        title={
          <>
            <Typography.Title level={5} style={{ margin: "0 auto" }}>
              Query: {data.checkin_id}
            </Typography.Title>
          </>
        }
        onClose={handleCancel}
        footer={[
          <Row justify="space-between" align="bottom">
            <Col span={20}>
              <TextArea
                disabled={
                  [1, 2, 3].includes(Number(claim.claims_status)) ||
                  [1, 3].includes(Number(data.claim_flag))
                }
                rows={2}
                value={messageText}
                placeholder="Write Full Description of the Query...."
                onChange={composeMessage}
                id={"message"}
              />
            </Col>
            <Col>
              <Button
                key="back"
                onClick={handleOk}
                style={{ color: "skyblue", fontSize: "1em" }}
                disabled={
                  [1, 2, 3].includes(Number(claim.claims_status)) ||
                  [1, 3].includes(Number(data.claim_flag)) ||
                  !messageText.length
                }
              >
                {" "}
                Send{" "}
              </Button>
            </Col>
          </Row>,
        ]}
        width={500}
      >
        <Space
          gutter={[8, 16]}
          direction={"vertical"}
          id="chatContainer"
          style={{ width: "100%" }}
        >
          {loading ? (
            <Col>
              <Spin size="large" />
            </Col>
          ) : !queries.length ? (
            <Empty description={<span>No Queries</span>} />
          ) : (
            <>
              {queries
                .sort((a, b) => Date.parse(a.date) - Date.parse(b.date))
                .map((q, i) => (
                  <Row
                    id={`query_${i}`}
                    justify={!Number(q.source) ? "end" : "start"}
                  >
                    <Col
                      span={16}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        lineHeight: "18px",
                        width: "fit-content",
                        color: `${!Number(q.source) ? "red" : "green"}`,
                        border: `${
                          !Number(q.source)
                            ? "1px solid red"
                            : "1px solid green"
                        }`,
                        borderRadius: "10px",
                        padding: "10px",
                      }}
                    >
                      <p>{q.content}</p>
                      <span
                        style={{ textAlign: "right", justifySelf: "flex-end" }}
                      >
                        {new Date(q.date).toLocaleString()}
                      </span>
                    </Col>
                  </Row>
                ))}
            </>
          )}
        </Space>
      </Drawer>
    </>
  );
};

const ClientDataHook = (props) => {
  const style = {
    ...props.style,
    display: "flex",
    color: "black",
    fontSize: "1.3em",
    textAlign: "left",
    padding: ".2rem",
    borderWidth: ".2em",
    borderStyle: "solid",
    borderColor: "rgba(0, 0, 0, .4)",
    borderRadius: ".5em",
  };

  return (
    <>
      <Row className="card-c" style={style} align="middle">
        <Col span={4} className="img-round img-medium">
          <img
            src={
              props.data.pic_name ? `${uri_img}${props.data.pic_name}` : logo
            }
            alt=""
            style={{
              width: "120px",
              height: "120px",
              display: "flex",
              alignSelf: "center",
              justifySelf: "center",
              margin: ".3em",
            }}
            className="rounded-circle"
          />
        </Col>
        <Col
          span={20}
          className="card-c-details"
          style={{
            display: "flex",
            flexDirection: "column",
            alignSelf: "center",
            justifySelf: "center",
            paddingLeft: "30px",
          }}
        >
          <Row className="card-c-datarow">
            <Col span={10} className="card-c-label">
              Patient Full Name:
            </Col>
            <Col className="card-c-value">
              {" "}
              {props.data.surname ? props.data.surname.toUpperCase() : ""},{" "}
              {props.data.first_name ? props.data.first_name : ""}{" "}
              {props.data.middle_name ? props.data.middle_name : ""}
            </Col>
          </Row>
          <Row className="card-c-datarow">
            <Col span={10} className="card-c-label">
              Patient Insurance ID:
            </Col>
            <Col className="card-c-value">
              {" "}
              {props.data.insurance_no ? props.data.insurance_no : ""}
            </Col>
          </Row>
          <Row className="card-c-datarow">
            <Col span={10} className="card-c-label">
              Patient Premium Type:
            </Col>
            <Col className="card-c-value">
              {" "}
              {props.data.insurance_package
                ? props.data.insurance_package
                : ""}{" "}
              | {props.data.premium_type ? props.data.premium_type : ""}
            </Col>
          </Row>
          <Row className="card-c-datarow">
            <Col span={10} className="card-c-label">
              Patient Premium Status:
            </Col>
            <Col className="card-c-value">
              {" "}
              {props.data.status ? `Active` : "Expired"}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default ClaimsHook;
